import { atom, getDefaultStore, Getter, Setter } from 'jotai';
import InkyAPI, { ApiModel, DEFAULT_API_VERSION, InkyApiError, InkyApiStatus } from '../API/InkyAPI';
import { ApiError, ApiErrorStatus } from '../API/InkyApiV2';
import { AccountInfo, SignupResponseStatus } from '../Models/AccountInfo';
import { CheckoutOverlayVisible, LoginOverlayVisible, LoginType, LoginTypeStore } from './OverlayStore';
import CreateUserPayload from '../Models/CreateUserPayload';
import { atomWithStorage } from 'jotai/utils';
import axios, { AxiosError } from 'axios';
import { MyBooksAtom } from './MyBooksStore';
import { FetchCartAtom } from './CartStore';
import { Platform, CurrentPlatform } from './GenericAtoms';
import { ReadingMode } from '../InkyPen/Reader/Reader';
import { InstrumentationSessionList } from '../Models/Instrumentation/InstrumentationPayload';
import { isElectron } from 'react-device-detect';
import { TokenResponse } from '@react-oauth/google';
import inkyAPI from '../API/InkyAPI';
import { LoginResponse } from '../Models/LoginResponse';
import { AuthTicketResponse } from '../types/contextBridge';

// Login atom
export type LoginStatus =
    'Unknown' |
    'LoggedOut' |
    'LoggingIn' |
    'LoggedIn';

export const LoginStatusAtom = atom<LoginStatus>('Unknown');
LoginStatusAtom.onMount = () => {
    const store = getDefaultStore();
    store.get(AsyncAccessTokenAtom).then(() => {
        console.debug('LoginStatusAtom.onMount');
    });
};

export const AccountPollingIsRunning = atom<boolean>(false);
export const AccountSubscriptionIsRunning = atom<boolean>(false);

export const AccountInfoAtom = atom(new AccountInfo());

export const ForgotPasswordTimerAtom = atom(0);

export const MatureContentWarningAccepted = atomWithStorage('MatureContentWarningAccepted', false);


const SteamEncryptedAccessTokenIv = atomWithWebStorage<string>('SteamEncryptedAccessTokenIv', '');
const SteamEncryptedAccessToken = atomWithWebStorage<string>('SteamEncryptedAccessToken', '');
const SteamEncryptedRefreshTokenIv = atomWithWebStorage<string>('SteamEncryptedRefreshTokenIv', '');
const SteamEncryptedRefreshToken = atomWithWebStorage<string>('SteamEncryptedRefreshToken', '');
/**
 * This is used to Identify the entry when asking for the encrypted token from the backend.
 */
const SteamEncryptedTokenEntryId = atomWithWebStorage<string>('SteamEncryptedTokenEntryId', '');
const PersistentAccessTokenAtom = atomWithWebStorage<string>('AccessToken', '', isElectron ? sessionStorage : localStorage);

//Only exported for SwitchPaymentsApp which sets token from url
export const AccessTokenAtom = atom((get) => {
        const storedValue = get(PersistentAccessTokenAtom);
        if (storedValue) {
            return get(PersistentAccessTokenAtom);
        }
    },
    async (_get, set, newAccessToken: string) => {
        set(PersistentAccessTokenAtom, newAccessToken);
    });

async function handleTokenValidationAndFetching(get: Getter, set: Setter, newAccessToken: string) {
    let currentNewAccessToken = newAccessToken;
    console.debug('handleTokenValidationAndFetching 0', newAccessToken, isElectron);

    if (isElectron) {
        console.debug('handleTokenValidationAndFetching A 1');
        const identity = 'InkyPenSteamTest';
        // If we are in electron the token is encrypted with AES, we need to
        // 1. get auth ticket from steam
        console.debug('Getting auth ticket from steam');
        const authTicket = await getRetryableSteamAuthTicket(identity);
        console.debug('handleTokenValidationAndFetching A 2');
        if (!authTicket.success) {
            console.error('Failed to get auth ticket from steam');
            return;
        }

        const steamEncryptedTokenEntryId = get(SteamEncryptedTokenEntryId);
        console.debug('handleTokenValidationAndFetching A 3');
        if (steamEncryptedTokenEntryId === '' || steamEncryptedTokenEntryId === null || steamEncryptedTokenEntryId === undefined || steamEncryptedTokenEntryId === 'undefined') {
            //Temporarily logging as error, should not be an issue if the token entry id is not set because user was legitimately not logged in.
            console.error('SteamEncryptedTokenEntryId is not set');

        } else {
            try {
                console.debug('handleTokenValidationAndFetching A 4');
                // 2. ask backend to verify session and get AES key

                console.debug('Getting AES key from backend');
                const AESKey = await GetSteamEncryptionKeyWithAuthTicket(authTicket.ticket, identity, steamEncryptedTokenEntryId);


                console.debug('AESKey', AESKey);

                // 3. decrypt token
                const EncryptedAccessToken = get(SteamEncryptedAccessToken);
                const EncryptedRefreshToken = get(SteamEncryptedRefreshToken);
                console.debug('EncryptedAccessToken', EncryptedAccessToken);
                console.debug('EncryptedRefreshToken', EncryptedRefreshToken);


                if (EncryptedAccessToken && AESKey) {
                    console.debug('Decrypting access token');
                    currentNewAccessToken = await decryptSymmetric(
                        EncryptedAccessToken,
                        get(SteamEncryptedAccessTokenIv),
                        AESKey,
                    );
                    console.debug('Decrypted access token', currentNewAccessToken);

                }

                if (EncryptedRefreshToken && AESKey) {
                    console.debug('Decrypting refresh token');
                    const decryptedRefreshToken = await decryptSymmetric(
                        EncryptedRefreshToken,
                        get(SteamEncryptedRefreshTokenIv),
                        AESKey,
                    );
                    console.debug('Decrypted refresh token', decryptedRefreshToken);
                    set(PersistentRefreshToken, decryptedRefreshToken);
                }
            } catch (e) {
                if (e instanceof DOMException && e.INVALID_ACCESS_ERR) {
                    console.error('Failed to decrypt token', e);
                    // If we can't decrypt we should clear the token
                    set(SteamEncryptedAccessToken, null);
                    set(SteamEncryptedAccessTokenIv, null);
                    set(SteamEncryptedRefreshToken, null);
                    set(SteamEncryptedRefreshTokenIv, null);
                    set(SteamEncryptedTokenEntryId, null);

                } else if (e instanceof InkyApiError) {
                    console.error('Failed to decrypt token', e);
                    // If we can't decrypt we should clear the token
                    set(SteamEncryptedAccessToken, null);
                    set(SteamEncryptedAccessTokenIv, null);
                    set(SteamEncryptedRefreshToken, null);
                    set(SteamEncryptedRefreshTokenIv, null);
                    set(SteamEncryptedTokenEntryId, null);
                } else {

                    console.error('Failed to decrypt token', e);
                    throw e;
                }
            }
        }


        // 4. set token in InkyAPI...
    }
    console.debug('handleTokenValidationAndFetching 2', currentNewAccessToken);

    // If we unset the access-token we intend to log out
    if (currentNewAccessToken == null) {
        set(PersistentAccessTokenAtom, null); // Set the reference to null
        set(LoginStatusAtom, 'LoggedOut'); // We can set the loginStatus to logged out
        return;
    }

    // If new AccessToken is set, lets verify that it is valid.

    // Validate by attempting to ping the Account-info endpoint.
    try {
        console.debug('handleTokenValidationAndFetching 413241');
        const accountInfo = await FetchAccountInfo(currentNewAccessToken);
        console.debug('handleTokenValidationAndFetching 1235', accountInfo);
        set(AccountInfoAtom, accountInfo);
    } catch (e) {
        console.warn('[AccountStore] Could not validate AccountInfo', e);
        if (e instanceof ApiError) {

            if (e.status == ApiErrorStatus.InvalidAccessToken) {

                // Tro to fetch a new AccessToken
                // Request new access-token based on refresh-token.
                try {
                    // const newAccessToken = await InkyApi.LoginWithRefreshToken();
                    const refreshToken = get(PersistentRefreshToken);
                    const newAccessToken = await LoginWithProvidedRefreshToken(refreshToken);
                    currentNewAccessToken = newAccessToken.access_token;
                    try {
                        const accountInfo = await FetchAccountInfo(currentNewAccessToken);
                        set(AccountInfoAtom, accountInfo);
                        await set(AccessTokenAtom, currentNewAccessToken);
                    } catch (e) {
                        console.error('Failed setting account-info twice.', e);
                        console.error(e);
                    }


                } catch (e) {
                    set(LoginStatusAtom, 'LoggedOut');
                    set(PersistentAccessTokenAtom, null);
                    set(PersistentRefreshToken, null);

                    console.error('Api Error', e);
                    return;
                }
            }
        } else {
            if (e.status === 404) {
                console.error('RefreshToken not found', e);
                set(LoginStatusAtom, 'LoggedOut');
                set(PersistentAccessTokenAtom, null);
                set(PersistentRefreshToken, null);
                return;
            } else {
                console.error('Something went wrong while requesting accountInfo', e);
                throw (e);
            }
        }

    }

    console.debug('handleTokenValidationAndFetching 9858', currentNewAccessToken);

    if (currentNewAccessToken === undefined) {
        // if we somehow can't fetch the access token with refresh token
        set(LoginStatusAtom, 'LoggedOut');
        set(PersistentAccessTokenAtom, newAccessToken);
    } else {
        if (LoginTypeStore.getLoginType() === LoginType.Checkout) {
            set(LoginOverlayVisible, false); // Disables the loginOverlay
            set(CheckoutOverlayVisible, true);
        }
        set(LoginStatusAtom, 'LoggedIn');
        set(PersistentAccessTokenAtom, currentNewAccessToken);
    }

    // LoginTypeStore.setLoginType(LoginType.Normal);
    console.debug('handleTokenValidationAndFetching 512521', currentNewAccessToken);
    set(FetchCartAtom).then(
        () => {
            console.debug('handleTokenValidationAndFetching 512521', 'Fetched cart');
        },
    );
    console.debug('handleTokenValidationAndFetching g4342fg2', currentNewAccessToken);
}

//endregion


const PersistentRefreshToken = atomWithWebStorage<string>('RefreshToken', '');
export const RefreshTokenAtom = atom(
    (get) => {
        const storedValue = get(PersistentRefreshToken);
        if (storedValue) {
            return get(PersistentRefreshToken);
        }
    },
    (_get, set, refreshToken: string) => {
        set(PersistentRefreshToken, refreshToken);
        if (refreshToken != null) {
            set(LoginStatusAtom, 'LoggedIn');
        } else {
            set(LoginStatusAtom, 'LoggedOut');
        }
    },
);

//endregion

//region Login
export interface Credentials {
    email: string;
    password: string;
}

export const LoginErrorMessageAtom = atom<string | false>(false);
export const LoginAtom = atom(
    get => get(LoginStatusAtom),
    async (_get, set, payload: Credentials) => {
        set(LoginStatusAtom, 'LoggingIn');
        set(LoginErrorMessageAtom, false);


        if (!payload.email || !payload.password) {
            set(LoginStatusAtom, 'LoggedOut');
            return;
        }

        try {
            const loginResponse = await login(payload.email, payload.password);

            if (loginResponse.refresh_token != null) {
                await set(AccessTokenAtom, loginResponse.access_token);
                set(RefreshTokenAtom, loginResponse.refresh_token);
                set(LoginStatusAtom, 'LoggedIn');

                // If we are in electron we want to encrypt the token and store the key in the backend then store the encrypted token in localstorage

                if (isElectron) {
                    const identity = 'InkyPenSteamTest';

                    const authTicket = await getRetryableSteamAuthTicket(identity);
                    if (!authTicket.success) {
                        console.error('Failed to get auth ticket from steam');
                        return;
                    }

                    const AESKey = generateKey();
                    const EncryptedAccessToken = await encryptSymmetric(loginResponse.access_token, AESKey);
                    const EncryptedRefreshToken = await encryptSymmetric(loginResponse.refresh_token, AESKey);
                    ////////// Test decrypting ////////////////
                    const decryptedAccessToken = await decryptSymmetric(EncryptedAccessToken.ciphertext, EncryptedAccessToken.iv, AESKey);

                    const decryptedRefreshToken = await decryptSymmetric(EncryptedRefreshToken.ciphertext, EncryptedRefreshToken.iv, AESKey);

                    console.debug('Decrypted access token', decryptedAccessToken, loginResponse.access_token, decryptedAccessToken === loginResponse.access_token);
                    console.debug('Decrypted refresh token', decryptedRefreshToken, loginResponse.refresh_token, decryptedRefreshToken === loginResponse.refresh_token);

                    console.debug('Decrypted with AeSKey', AESKey);
                    //////////
                    set(SteamEncryptedAccessToken, EncryptedAccessToken.ciphertext);
                    set(SteamEncryptedAccessTokenIv, EncryptedAccessToken.iv);
                    set(SteamEncryptedRefreshToken, EncryptedRefreshToken.ciphertext);
                    set(SteamEncryptedRefreshTokenIv, EncryptedRefreshToken.iv);
                    const steamEncryptedTokenEntryId = await StoreSteamEncryptedTokenEntryId(
                        authTicket.ticket, identity, AESKey);
                    set(SteamEncryptedTokenEntryId, steamEncryptedTokenEntryId);
                }

            }
        } catch (e) {

            if (e instanceof AxiosError) {

                if (e.response.status === 403) {
                    set(LoginErrorMessageAtom, 'Wrong password.');
                }


                console.log('Network error!', e);
            } else {
                console.error('Unknown error', e);
            }


            set(LoginStatusAtom, 'LoggedOut');
        }

        await set(FetchCartAtom);
    },
);
//endregion

//region Logout
export const LogoutAtom = atom(
    get => {
        get(LoginStatusAtom);
    },
    (get, set) => {
        // localStorage.removeItem('AccessToken');
        let accountInfo = get(AccountInfoAtom);
        if (accountInfo.roles) {
            accountInfo = { ...accountInfo, roles: accountInfo.roles.filter(role => role !== 'admin') };
            set(AccountInfoAtom, accountInfo);
        }
        set(RefreshTokenAtom, null);
        set(AccessTokenAtom, null).then(() => {
            set(LoginStatusAtom, 'LoggedOut');
        });
        localStorage.removeItem('forgot_password');
        set(AccountInfoAtom, new AccountInfo());
        set(MyBooksAtom, []);
        set(SteamEncryptedAccessToken, null);
        set(SteamEncryptedAccessTokenIv, null);
        set(SteamEncryptedRefreshToken, null);
        set(SteamEncryptedRefreshTokenIv, null);
        set(SteamEncryptedTokenEntryId, null);
    },
);
//endregion

//region SignUp
export const SignUpStatus = atom<SignupResponseStatus | false>(false);
export const SignUpActionAtom = atom(
    (get) => get(SignUpStatus),
    async (_get, set, update: Credentials) => {
        console.log('[SignUp] Attempting to register new account.');

        const signupResponse = await Signup(new CreateUserPayload(update.email, update.password));
        console.debug('[SignUp] Signup response:', signupResponse);

        switch (signupResponse) {
            case SignupResponseStatus.Successful:
                // Log user in
                await set(LoginAtom, { email: update.email, password: update.password });
                set(SignUpStatus, signupResponse);
                break;
            case SignupResponseStatus.TenantError:
                // Update the status. Signup will re-render and show error message
                set(SignUpStatus, signupResponse);
                break;
            case SignupResponseStatus.InvalidEmail:
                // Update the status. Signup wil re-render and show error message
                set(SignUpStatus, signupResponse);
                break;
            case SignupResponseStatus.UnknownError:
                // Update the status. Signup wil re-render and show error message
                set(SignUpStatus, signupResponse);
                break;

        }

    },
);

//endregion

//region Admin-stores
export const AdminBuyPass = atomWithStorage('AdminBuyPass', false);
//endregion


/*export const CanUpdatePaymentDetails = atom(async get => {
    const platform = get(PlatformAtom);
    //get account info so this refreshes when the user logs in
    get(AccountInfoAtom);

    const platformString = platform === Platform.Web ? 'web' : 'steam';

    return await InkyApi.GetCanUpdatePaymentDetails(platformString).then((canUpdatePaymentDetails) => {
        return canUpdatePaymentDetails;
    }).catch(
        (error) => {
            console.error('Failed to get canUpdatePaymentDetails', error);
            return false;
        },
    );
});*/

//region Instrumentation

// Main instrumentation atom. Used for real time tracking of user reading behavior.
export const InstrumentationAtom = atom<InstrumentationSessionList>({ sessions: [] });
// Whenever we prepare a push to the backend, we move the instrumentation data to this atom.
// Only push to this if its empty. If it's not empty, It's ready to be pushed to the backend.
// The logic for preparing and pushing to backend is done in index.tsx to be able to push independently of the reader-view.
export const InstrumentationPendingPushAtom = atom<InstrumentationSessionList>({ sessions: [] });
// Setter-atom for adding pageSessions to the instrumentation.
export const updateSessionData = atom(
    null,
    (get, set, sessionData: { productId: number, pageNumber: number }) => {
        //console.debug("[Instrumentation] Updating session data", sessionData);

        const currentInstrumentation = get(InstrumentationAtom);
        const platform = CurrentPlatform;
        //console.log("[Instrumentation] Current Instrumentation", currentInstrumentation);

        // Create a ref to the current session. if there is none, create one.
        let currentSession = currentInstrumentation.sessions.find(session => session.comicId === sessionData.productId);
        if (!currentInstrumentation.sessions.find(session => session.comicId === sessionData.productId)) {
            currentInstrumentation.sessions.push({
                comicId: sessionData.productId,
                mode: ReadingMode.Page,
                platform: Platform[platform],
                pageSessions: [],
            });
            currentSession = currentInstrumentation.sessions.find(session => session.comicId === sessionData.productId);
        }

        // Create a ref to any active pageSessions that has not concluded.
        const activePageSessions = currentSession.pageSessions.filter(pageSession => pageSession.end === undefined);
        if (activePageSessions.length > 0) {
            const activePageSession = activePageSessions[0];
            activePageSession.end = new Date().toISOString();
            console.debug(`[Instrumentation] Concluded page ${activePageSession.pageNumber}. Duration: ${new Date(activePageSession.end).getTime() - new Date(activePageSession.start).getTime()}ms`);
        }

        // Create a new pageSessions and add it to the current session.
        currentSession.pageSessions.push({
            pageNumber: sessionData.pageNumber,
            start: new Date().toISOString(),
            end: undefined,
        });

        // Update the instrumentation atom
        set(InstrumentationAtom, currentInstrumentation);
        //console.log("[Instrumentation] Updated Instrumentation", currentInstrumentation);


    },
);
// Setter atom for preparing a push to the backend.
export const prepareInstrumentationPushAtom = atom(
    null,
    (get, set) => {
        const currentInstrumentation = get(InstrumentationAtom);
        const pendingInstrumentation = get(InstrumentationPendingPushAtom);

        if (pendingInstrumentation.sessions.length === 0 && currentInstrumentation.sessions.length > 0) {
            console.debug('[Instrumentation] Preparing instrumentation for push to backend.');

            //The page currently being read may not have it's time set, so let's
            // - Record the time spent reading it so far.
            // - Add it to the next page instrumentation spent so that any additional time spent reading it can also be recorded.
            const ongoingInstrumentation = { sessions: [] }
            for (const session of currentInstrumentation.sessions) {
                const activePageSessions = session.pageSessions.filter(pageSession => pageSession.end === undefined);
                if (activePageSessions.length > 0) {
                    const activePageSession = activePageSessions[0];
                    activePageSession.end = new Date().toISOString();
                    console.debug(`[Instrumentation] Concluded page ${activePageSession.pageNumber}. Duration: ${new Date(activePageSession.end).getTime() - new Date(activePageSession.start).getTime()}ms`);

                    ongoingInstrumentation.sessions.push(Object.assign({}, session, {
                        pageSessions: [Object.assign({}, activePageSession, {
                            start: activePageSession.end,
                            end: undefined,
                        })],
                    }));
                }
            }

            set(InstrumentationPendingPushAtom, currentInstrumentation);
            set(InstrumentationAtom, ongoingInstrumentation);
        }
    },
);
// Setter atom for the actual push to the backend.
export const sendPreparedInstrumentationToBackendAtom = atom(
    null,
    async (get, set) => {
        const pendingInstrumentation = get(InstrumentationPendingPushAtom);

        if (pendingInstrumentation.sessions.length === 0) {
            return;
        }

        console.debug('[Instrumentation] Sending instrumentation to backend.');
        const response = await inkyAPI.sendInstrumentationData(pendingInstrumentation, null);
        if (response.status != 200) { // 200 - OK
            console.warn('[Instrumentation] Sending instrumentation failed. Retrying next time.');
            return;
        }

        console.debug('[Instrumentation] Instrumentation success sent. Cleaning up.', response.data);
        set(InstrumentationPendingPushAtom, { sessions: [] });


    },
);


//endregion
/**
 * Version of atomWithStorage which loads value on first render.
 * see https://github.com/pmndrs/jotai/discussions/1737 for more info
 * @param key
 * @param initialValue
 * @param storage
 */
export function atomWithWebStorage<Value>(
    key: string,
    initialValue: Value,
    storage = localStorage,
) {
    const storedValue = storage.getItem(key);
    const isString = typeof initialValue === 'string';
    //console.debug('atomWithWebStorage 1', key, storedValue, isString);

    const storageValue = storedValue
        ? isString
            ? storedValue
            : storedValue === 'true'
        : undefined;
    //console.debug('atomWithWebStorage 2', key, storage, storageValue);

    const baseAtom = atom(storageValue ?? initialValue);

    return atom(
        get => get(baseAtom) as Value,
        (_get, set, nextValue: Value) => {
            //console.debug('atomWithWebStorage 3', key, nextValue);
            set(baseAtom, nextValue);
            storage.setItem(key, nextValue?.toString());
        },
    );
}

//region Crypto

function generateKey() {
    // Generate a random 32-byte key
    const randomValues = crypto.getRandomValues(new Uint8Array(32));
    return btoa(String.fromCharCode(...randomValues));
}

// Function to convert Uint8Array to Base64
const uint8ArrayToBase64 = (uint8Array: Uint8Array) => {
    let binary = '';
    const len = uint8Array.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(uint8Array[i]);
    }
    return btoa(binary);
};

// Function to convert Base64 to Uint8Array
const base64ToUint8Array = (base64: string) => {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
};

export const encryptSymmetric = async (plaintext: string, key: string) => {
    // Create a random 96-bit initialization vector (IV)
    const iv = crypto.getRandomValues(new Uint8Array(12));

    // Encode the text you want to encrypt
    const encodedPlaintext = new TextEncoder().encode(plaintext);

    // Prepare the secret key for encryption
    const secretKey = await crypto.subtle.importKey(
        'raw',
        base64ToUint8Array(key), // Use the new function for base64 to Uint8Array
        {
            name: 'AES-GCM',
            length: 256,
        },
        true,
        ['encrypt', 'decrypt'],
    );

    // Encrypt the text with the secret key
    const ciphertext = await crypto.subtle.encrypt(
        {
            name: 'AES-GCM',
            iv: iv,
        },
        secretKey,
        encodedPlaintext,
    );

    // Return the encrypted text (ciphertext) and the IV encoded in base64
    return {
        ciphertext: uint8ArrayToBase64(new Uint8Array(ciphertext)), // Use the new function for Uint8Array to base64
        iv: uint8ArrayToBase64(iv), // Use the new function for IV
    };
};

export const decryptSymmetric = async (ciphertext: string, iv: string, key: string) => {
    console.debug('Decryption started', { ciphertext, iv, key });
    // Prepare the secret key
    const secretKey = await crypto.subtle.importKey(
        'raw',
        base64ToUint8Array(key), // Use the new function for base64 to Uint8Array
        {
            name: 'AES-GCM',
            length: 256,
        },
        true,
        ['encrypt', 'decrypt'],
    );

    // Decode base64 strings safely
    const decodedIv = base64ToUint8Array(iv); // Use the new function for IV
    const decodedCiphertext = base64ToUint8Array(ciphertext); // Use the new function for ciphertext

    console.debug('Decoded Ciphertext (Uint8Array):', decodedCiphertext);
    console.debug('Decoded IV (Uint8Array):', decodedIv);

    // Decrypt the encrypted text (ciphertext) with the secret key and IV
    const cleartext = await crypto.subtle.decrypt(
        {
            name: 'AES-GCM',
            iv: decodedIv,
        },
        secretKey,
        decodedCiphertext,
    );

    // Decode the text and return it
    return new TextDecoder().decode(cleartext);
};

/**
 * Wrapper for AccessToken so all other Api Wrappers can use it naively
 *
 * Checks if AccessToken is loaded, if not it will load it. If electron it should also go through the steam auth flow before letting other api calls through.
 */
export const AsyncAccessTokenAtom = atom(async (get) => {
    const storeSet = getDefaultStore().set;
    const initialAccessToken = get(AccessTokenAtom);
    const steamEncryptedAccessToken = get(SteamEncryptedAccessToken);
    const weHaveAccessToken = initialAccessToken !== undefined && initialAccessToken !== null && initialAccessToken !== '';
    const weHaveSteamEncryptedToken = steamEncryptedAccessToken !== undefined && steamEncryptedAccessToken !== null && steamEncryptedAccessToken !== '';

    const loginStatus = get(LoginStatusAtom);
    const accountInfo = get(AccountInfoAtom);
    console.debug('AsyncAccessTokenAtom', weHaveAccessToken, weHaveSteamEncryptedToken, loginStatus);

    if (loginStatus === 'Unknown' ||
        (!weHaveAccessToken && weHaveSteamEncryptedToken) ||
        (loginStatus === 'LoggedIn' && accountInfo.userid === undefined)) {
        const startValue = get(AccessTokenAtom);
        /*    await mutex.runExclusive(async () => {*/
        console.debug('AsyncAccessTokenAtom', 'Fetching token');
        await handleTokenValidationAndFetching(get, storeSet, startValue);
        /*       });*/
    }
    console.debug('AsyncAccessTokenAtom', 'Returning token');
    return get(AccessTokenAtom);
});


// region Network adapter
// Keeping these here as they should be independent of any version of the InkyApi, and most of these have a different relationship to the access token than the InkyApis.


async function GetSteamEncryptionKeyWithAuthTicket(authTicket: string, identity: string, tokenEntryId: string): Promise<string> {
    console.debug('Getting steam encryption key with auth ticket', authTicket, identity, tokenEntryId);
    const result = await postV4<{ ticket: string, identity: string, tokenEntryId: string }, ApiModel<{
        aesKey: string
    }>>(
        'account/SteamRetrieveEncryptionEntry',
        { ticket: authTicket, tokenEntryId: tokenEntryId, identity: identity }, null, null,
    );
    if (result.status.type !== 'Success') {
        console.error('Failed to authenticate steam', result.status.message);
        throw new InkyApiError('Failed to authenticate steam', InkyApiStatus.Default);
    }
    console.debug('Steam encryption key retrieved', result.response.aesKey);
    return result.response.aesKey;
}

async function StoreSteamEncryptedTokenEntryId(authTicket: string, identity: string, aesKey: string): Promise<string> {
    const result = await postV4<{ ticket: string, identity: string, aesKey: string }, ApiModel<{
        identifier: string
    }>>('/account/SteamStoreEncryptionEntry', { ticket: authTicket, identity: identity, aesKey: aesKey });
    if (result.status.type !== 'Success') {
        console.error('Failed to store encrypted token entry id', result.status.message);
        throw new InkyApiError('Failed to store encrypted token entry id', InkyApiStatus.Default);
    }
    return result.response.identifier;
}


/**
 *  T is payload type, U is return type
 * @param url
 * @param payload
 * @param abortSignal
 * @param accessToken can be null for calls which do not require authentication
 */
async function postV4<T, U>(url: string, payload: T, abortSignal?: AbortSignal, accessToken?: string): Promise<U> {


    const fullUrl = new URL(url, baseUrl);
    fullUrl.searchParams.append('api-version', DEFAULT_API_VERSION);

    return (axios.post<U>(fullUrl.href, payload, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'content-type': 'text/json',
        },
        signal: abortSignal,
    })).then(res => res.data);
}

async function FetchAccountInfo(accessToken: string): Promise<AccountInfo> {

    const fullUrl = new URL('/account', baseUrl);

    console.debug('Fetching account info with provided access token.');
    return axios.get(fullUrl.href, { headers: { Authorization: `Bearer ${accessToken}` } })
        .then(function(response) {
            return response.data;
        })
        .catch((error: AxiosError) => {
            if (error.isAxiosError) {
                console.debug('Axios error response', error.response);

                if (error.response.status == 401) {
                    // Unauthorized error.
                    throw new ApiError('Unauthorized request.', ApiErrorStatus.InvalidAccessToken);
                }

                console.log('Axios Error', error.response.status);
            }
            throw error;
        });


}


async function LoginWithProvidedRefreshToken(refreshToken: string): Promise<TokenResponse> {
    if(refreshToken === undefined || refreshToken === null || refreshToken === "undefined") {
        throw new Error('Making login request with Refresh token is null or undefined');
    }
    console.log('this.RefreshToken', refreshToken);
    return await post<TokenResponse>('/account/token', { 'refresh_token': refreshToken });
}

const baseUrl = process.env.REACT_APP_BASE_URL;
async function post<T>(url: string, payload: Record<string, unknown>): Promise<T> {
    const fullUrl = new URL(url, baseUrl);
    const response = await axios.post<T>(fullUrl.href, payload, { headers: { Authorization: `Bearer ` } });
    return response.data;
}

async function Signup(userDetails: CreateUserPayload): Promise<SignupResponseStatus> {

    // Encode the credentials
    userDetails.email = btoa(userDetails.email);
    userDetails.password = btoa(userDetails.password);

    const response = await getPostHeader('/account/signupCustomerV2', userDetails);
    try {
        return SignupResponseStatus[SignupResponseStatus[response]];
    } catch (e) {
        return SignupResponseStatus.UnknownError;
    }
}

async function getPostHeader(url: string, payload: CreateUserPayload): Promise<number> {
    const fullUrl = new URL(url, baseUrl);
    const response = await axios.post(fullUrl.href, payload, { headers: { Authorization: `Bearer`} });

    return response.status;
}

async function login(email: string, password: string) {
    return InkyAPI.post('/account/token', { UserName: email, Password: password })
        .then(res => {
            console.debug('login with email and password', res.data);
            const data = res.data as unknown;
            return data as LoginResponse;
        });
}

async function getRetryableSteamAuthTicket(identity: string):Promise<AuthTicketResponse> {
    const getRetryableSteamAuthTicket = async (delay: number, times: number):Promise<AuthTicketResponse> => {
        try {
            return (await window.electron.getAuthTicketForWebApi(identity));
        } catch (e) {
            if (times > 7) {
                //dialog.showErrorBox('Steam Error', `Failed to get steam session ticket: ${e}`);
                //app.quit();
                new Error(`Failed to get steam auth ticket after ${times} times: ${e}`);
            } else {
                await new Promise((resolve) => setTimeout(resolve, delay));
                return await getRetryableSteamAuthTicket(delay * 2, times + 1);
            }
        }

    };
    return await getRetryableSteamAuthTicket(1000, 0);
}


